<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="taxpodAccEnrollDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          taxPOD Account Enrollment
        </v-toolbar>

        <div class="pa-4">Are you sure to submit ?</div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalTaxpodAccEnroll()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="taxpodAccEnrollDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="refundEmailRejectDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Reject Application
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to reject this application <br />
          <span class="text-danger"
            >*(Application will flow back to pending payment)</span
          >
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalRefundEmailReject()"
          >
            Reject
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="refundEmailRejectDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar color="#063058">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="isTaxpodAdminRoles && alertError.length == 0">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  class="hidden-xs-only text-white mr-5"
                  :loading="apiSubmit.isLoading"
                  @click="refundEmailRejectDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-remove
                  </v-icon>
                </v-btn>
              </template>
              <span>Reject Refund Email</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="taxpodAccEnrollDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Submit</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <div>
        <!-- Enrollment Date -->
        <v-col cols="12" sm="6" md="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formData.enrollmentDate"
                label="Enrollment Date (YYYY-MM-DD)"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.enrollmentDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-row>
          <v-col cols="12">
            <div class="col-8 pa-5">
              <!-- Company Name -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Company Name: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(formData.companyName)"
                    >
                      {{ formData.companyName }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- Salesperson -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Salesperson: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(formData.salesperson)"
                    >
                      {{ formData.salesperson }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- Total Price -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Total Price: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(formData.price)"
                    >
                      {{ formData.price }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- taxPOD Type -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Total Type: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(formData.taxpodType)"
                    >
                      {{ formData.taxpodType }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- HRDC Invoice No. -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> HRDC Invoice No: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(formData.hrdcInvoiceNo)"
                    >
                      {{ formData.hrdcInvoiceNo }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- HRDC Invoice Attachment -->
              <v-row
                no-gutters
                class="d-flex align-items-center pb-2"
                v-if="formData.hrdcInvoiceFileUrl"
              >
                <v-col cols="2">
                  <span class="text-subtitle-2"> HRDC Invoice: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      large
                      color="red lighten-2"
                      :href="formData.hrdcInvoiceFileUrl"
                      @click.prevent="
                        previewOrDownloadAttachment(
                          formData.hrdcInvoiceFileUrl,
                          'preview'
                        )
                      "
                    >
                      View
                      <v-icon>mdi-file-outline</v-icon>
                    </v-btn>
                    <v-btn
                      large
                      color="blue lighten-2"
                      :href="formData.hrdcInvoiceFileUrl"
                      download
                      @click.prevent="
                        previewOrDownloadAttachment(
                          formData.hrdcInvoiceFileUrl,
                          'download'
                        )
                      "
                    >
                      Download
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <hr />

        <v-row>
          <v-col cols="12">
            <div class="col-8 px-5 pt-5">
              <!-- First Name -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> First Name: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(formData.firstName)"
                    >
                      {{ formData.firstName }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- Last Name -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Last Name: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(formData.lastName)"
                    >
                      {{ formData.lastName }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- Contact No. -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Contact No: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(formData.contactNo)"
                    >
                      {{ formData.contactNo }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- Email -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Email: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(formData.email)"
                    >
                      {{ formData.email }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </div>

            <div
              v-for="(enrollment, index) in formData.dynamicEnrollmentDetails"
              :key="index"
              class="col-8 px-5"
            >
              <hr />
              <!-- First Name -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> First Name: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(enrollment.firstNameAccEnrollment)"
                    >
                      {{ enrollment.firstNameAccEnrollment }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- Last Name -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Last Name: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(enrollment.lastNameAccEnrollment)"
                    >
                      {{ enrollment.lastNameAccEnrollment }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- Contact No. -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Contact No: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(enrollment.contactNoAccEnrollment)"
                    >
                      {{ enrollment.contactNoAccEnrollment }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- Email -->
              <v-row no-gutters class="d-flex align-items-center pb-2">
                <v-col cols="2">
                  <span class="text-subtitle-2"> Email: </span>
                </v-col>
                <v-col cols="8">
                  <v-btn-toggle>
                    <v-btn
                      class="btn-wrap-text"
                      active-class="no-active"
                      style="text-transform: none !important"
                      @click="copyContent(enrollment.emailAccEnrollment)"
                    >
                      {{ enrollment.emailAccEnrollment }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationDetails: new Api(),
    apiDownloadOrPreviewAttachment: new Api(),
    apiSubmit: new Api(),
    apiReject: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
      firstName: null,
      lastName: null,
      contactNo: null,
      email: null,
      companyName: null,
      salesperson: null,
      taxpodType: null,
      hrdcInvoiceNo: null,
      hrdcInvoiceFileUrl: null,
      price: null,
      enrollmentDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dynamicEnrollmentDetails: null,
    },
    errorField: {
      applicationNo: null,
      firstName: null,
      lastName: null,
      contactNo: null,
      email: null,
      enrollmentDate: null,
    },
    loadingDialog: false,
    taxpodAccEnrollDialog: false,
    editMode: false,
    refundEmailRejectDialog: false,
  }),
  async created() {
    this.loadingDialog = !this.loadingDialog;

    const fieldMap = {
      applicationNo: "applicationUuid",
      firstName: "firstNameAccEnrollment",
      lastName: "lastNameAccEnrollment",
      contactNo: "contactNoAccEnrollment",
      email: "emailAccEnrollment",
    };
    for (let field in fieldMap) {
      this.formData[field] = this.$store.state.hrdc.data[fieldMap[field]];
    }

    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetApplicationDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiDownloadOrPreviewAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/azure-download-or-preview-application-attachment`,
      },
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/enrollment_detail/create-enrollment-detail`,
        method: "post",
      },

      apiReject: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/reject-refund-email`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiGetApplicationDetails.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          let taxpodType = null;
          let stageToGetDate = null;
          if (data.taxpodType == 1) {
            taxpodType = "New Sale";
            stageToGetDate =
              this.hrdcData.stagePriority.taxpodAccountActivation;
          } else {
            taxpodType = "Renewal";
            stageToGetDate = this.hrdcData.stagePriority.hrdcClaimCompleted;
          }

          this.formData.salesperson = data.referByName;
          this.formData.companyName = data.oriCompanyName;
          this.formData.price = `RM ${this.formatNumber(data.totalPrice)}`;
          this.formData.taxpodType = taxpodType;
          this.formData.hrdcInvoiceNo = data.manualInvoiceToHrdc ?? "-";

          // Check whether got hrdc invoice or not.
          let hrdcInvoiceFileUrl = data.ApplicationAttachment.find(
            (item) =>
              item.stageId == this.hrdcData.stagePriority.manualInvoiceToHrdc
          );
          if (hrdcInvoiceFileUrl) {
            hrdcInvoiceFileUrl = hrdcInvoiceFileUrl.fileUrl;
            this.formData.hrdcInvoiceFileUrl = hrdcInvoiceFileUrl;
          }

          // Assign dynamic enrollment details.
          if (data.ApplicationEnrollmentDetail.length > 0) {
            this.formData.dynamicEnrollmentDetails =
              data.ApplicationEnrollmentDetail;
          }

          // If editMode, then append enrollment date.
          if (this.editMode) {
            const enrollmentDate = data.ApplicationLog.find(
              (item) => item.stageId == stageToGetDate
            ).startDate;
            this.formData.enrollmentDate =
              moment(enrollmentDate).format("YYYY-MM-DD");
          }
        }

        this.loadingDialog = !this.loadingDialog;
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationDetails.fetch();

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc.data.stageId];
    this.rolesAssignation(this.auth.roles);
    if (!this.isTaxpodAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
    }

    if (currentStageId >= this.hrdcData.stageSlug.taxpodAccountActivation) {
      this.alertError.push("You already enrolled taxPOD account");
      this.editMode = true;
    }

    if (currentStageId < this.hrdcData.stageSlug.paymentReceivedFromHrdc) {
      this.alertError.push("Please complete previous step");
    }
  },
  mounted() {},
  methods: {
    formatNumber(number) {
      if (number) {
        return Number(number).toLocaleString();
      }

      return number;
    },
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    modalTaxpodAccEnroll() {
      this.taxpodAccEnrollDialog = !this.taxpodAccEnrollDialog;
      this.submit();
    },
    copyContent(item) {
      const textarea = document.createElement("textarea");
      textarea.value = item;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      this.$store.dispatch("showMessage", {
        message: "Copied Successfully!",
        messageType: "info",
      });
    },
    redirectBack() {
      let route = "HrdcApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    async previewOrDownloadAttachment(fileUrl, action) {
      this.loadingDialog = !this.loadingDialog;

      const attachmentExt = fileUrl.split(".")[1].toLowerCase();

      fileUrl = fileUrl.replace(/\.[^.]+$/, (match) => match.toLowerCase());

      let responseType = "blob";
      let isImage = false;
      let blobType = `application/${attachmentExt}`;
      let streamUrl = this.apiDownloadOrPreviewAttachment.url;
      let downloadAttachmentName = `${this.formData.companyName} - ${this.formData.hrdcInvoiceNo}.${attachmentExt}`;

      if (this.isImage(fileUrl)) {
        responseType = "arraybuffer";
        isImage = true;
        blobType = `image/${attachmentExt.toLowerCase()}`;
      }

      await this.$axios
        .get(streamUrl, {
          responseType,
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            filePath: fileUrl,
            isImage,
          },
        })
        .then((response) => {
          if (action == "preview") {
            const blobUrl = URL.createObjectURL(
              new Blob([response.data], { type: blobType })
            );

            window.open(blobUrl, "_blank");
          } else if (action == "download") {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", downloadAttachmentName);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          if ((error.response.status == 404) | true) {
            this.$store.dispatch("showMessage", {
              message:
                "Failed to Preview/Download attachment, kindly contact administrator",
              messageType: "error",
              timeout: 2000,
            });
          }
        });

      this.loadingDialog = !this.loadingDialog;
    },
    async submit() {
      this.loadingDialog = !this.loadingDialog;
      let data = {
        applicationUuid: this.formData.applicationNo,
        enrollmentDate: this.formData.enrollmentDate,
        enrollById: this.auth.uuid,
      };

      this.apiSubmit.setParams(data);
      this.apiSubmit.fetch();
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialog = !this.loadingDialog;
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.applicationNotFound) {
              this.$store.dispatch("showMessage", {
                message: response.data.applicationNo,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.$router.push({
              name: "HrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
                tabIndex: 0,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    },
    modalRefundEmailReject() {
      this.loadingDialog = !this.loadingDialog;

      this.apiReject.setParams({
        applicationUuid: this.$route.query._ausk,
        userUuid: this.auth.uuid,
        rejectToStage: this.hrdcData.stagePriority.claimApprovedFromHrdc,
        rejectStageId:
          this.hrdcData.stagePriority.paymentReceivedFromHrdcRejected,
      });

      this.apiReject.setCallbackCompleted((response) => {
        try {
          this.loadingDialog = !this.loadingDialog;
          if (response.status) {
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });

            this.$router.push({
              name: "HrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
              },
            });
          }
        } catch (err) {
          this.loadingDialog = !this.loadingDialog;
          console.log(err);
        }
      });

      this.apiReject.fetch();
      this.refundEmailRejectDialog = false;
    },
  },
};
</script>

<style scoped>
.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.btn-wrap-text {
  color: black;
  height: auto !important;
  max-width: 100%;
  white-space: normal;
  &.v-btn:not(.v-btn--round).v-size--default {
    padding: 10px 16px;
  }
  .v-btn__content {
    flex: 1 0 fit-content;
  }
}
</style>
